.switch_wrapper{
    width: 50px;
    height: 26px;
    border-radius: 15px;
    border: 1px #CCC solid;
    position: relative;
    cursor: pointer;
    transition: .3s;

    .switch{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #CCC;
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
        transition: .3s;
    }
}

.active{
    border: 1px #EC722E solid;

    .switch{
        background: #EC722E;
        left: 25px;
    }
}